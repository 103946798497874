import React, { Fragment } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { PositionStyleEnum } from '../../../../../../../../../types';
import {
  FetchMessagesCacheKey,
  MessageClassifications,
  MessageID,
  MessageMessageTypes,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../../../messagesTypes';
import { CheckableMessagesProps } from '../../../../../../../../../common/hooks/useMessagesCheckable';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { MessageDropdownAddToDescriptionButton } from '..//MessageDropdownAddToDescriptionButton';
import { MessageDropdownAddToRecordsButton } from '..//MessageDropdownAddToRecordsButton';
import { MessageDropdownCheckTodoItemsButton } from '..//MessageDropdownCheckTodoItemsButton';
import { MessageDropdownCopyIdButton } from '..//MessageDropdownCopyIdButton';
import { MessageDropdownCopyUuidButton } from '..//MessageDropdownCopyUuidButton';
import { MessageDropdownCreateTodoListButton } from '..//MessageDropdownCreateTodoListButton';
import { MessageDropdownUpdateTodoListButton } from '..//MessageDropdownUpdateTodoListButton';
import { MessageDropdownDownloadAttachments } from '..//MessageDropdownDownloadAttachments';
import { MessageDropdownDownloadFileAttachments } from '..//MessageDropdownDownloadFileAttachments';
import { MessageDropdownDownloadSelectedLifestyles } from '..//MessageDropdownDownloadSelectedLifestyles';
import { MessageDropdownDownloadSelectedProducts } from '..//MessageDropdownDownloadSelectedProducts';
import { MessageDropdownDownloadSelectedMaterials } from '../MessageDropdownDownloadSelectedMaterials';
import { MessageDropdownFavoriteButton } from '..//MessageDropdownFavoriteButton';
import { MessageDropdownFlaggedButton } from '..//MessageDropdownFlaggedButton';
import { MessageDropdownForwardModalButton } from '..//MessageDropdownForwardModalButton';
import { MessageOpenInWhiteboardLink } from '../../../MessageOpenInWhiteboardLink';
import { MessageDropdownPropagateModalButton } from '..//MessageDropdownPropagateModalButton';
import { MessageDropdownRedButton } from '..//MessageDropdownRedButton';
import { MessageDropdownSendResult } from '..//MessageDropdownSendResult';
import { MessageDropdownPinnedButton } from '..//MessageDropdownPinnedButton';
import { MessageDropdownUncheckTodoItemsButton } from '..//MessageDropdownUncheckTodoItemsButton';
import { MessageDropdownUploadToLibraryButton } from '..//MessageDropdownUploadToLibraryButton';
import { PublishMessageButton } from '../../../../../../buttons/PublishMessageButton';
import { SendImagesToWhiteboardPageDynamicModalButton } from '../../../../../../../../whiteboards/components/buttons/SendImagesToWhiteboardPageModalButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';
import { MessageDropdownSelectButton } from '..//MessageDropdownSelectButton';
import { MessageDropdownVFCButton } from '..//MessageDropdownVFCButton';
import { MessageDropdownReplyModalButton } from '../MessageDropdownReplyModalButton';
import { MessageDropdownToggleAiNotRelevantButton } from '../MessageDropdownToggleAiNotRelevantButton';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { DropdownDivider } from '../../../../../../../../../helpers/DropdownDivider';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { Files } from '../../../../../../../../../utils/Files';

import {
  stringsKeys,
  tasksKeys,
  whiteboardsKeys,
  words
} from '../../../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../../../messagesConstants';
import { TasksPermissions } from '../../../../../../../../tasks/tasksConstants';

type MessageDropdownContentProps = {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  withPropagateButton?: boolean;
  withPinButton?: boolean;
  tooltipSingleton?: boolean;
  dropdownPlacement?: PopoverPlacement;
  popoverPositionStyle?: PositionStyleEnum;
} & CheckableMessagesProps;

function MessageDropdownContent({
  message,
  messagesCacheKey,
  onReplyMessage,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  withPropagateButton = false,
  withPinButton = false,
  checkedMessages,
  onSetCheckedMessage
}: MessageDropdownContentProps) {
  const currentUser = useCurrentUser();

  const images = map(
    filter(message.fileAttachments, ({ file }) => file && Files.isImage(file)),
    ({ file, height, name, width, id }) => ({
      fileUrl: file,
      height,
      name,
      width,
      fileAttachmentId: id
    })
  );

  const quantityTypesAttachments = sumBy(
    [
      message.fileAttachments,
      message.selectedProducts,
      message.selectedLifestyles
    ],
    (attachments) => (isEmpty(attachments) ? 0 : 1)
  );

  const withSubmitAsTaskResultsButton =
    message.task && size(message.fileAttachments) > 0;

  const withUploadToLibraryButton =
    size(message.fileAttachments) > 0 &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENTS_UPLOAD_TO_LIBRARY_BUTTON
    );

  const withSubmitSourceFilesButton =
    size(message.fileAttachments) > 0 &&
    message.task?.nanoId &&
    some(
      compact([
        MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON,
        currentUser.nanoId === message.user.nanoId
          ? MessagesPermissions.READ_SELF_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON
          : null
      ]),
      (action) => currentUser.hasPermissions(action)
    );

  const projectNanoId = message.project?.nanoId;

  const withTodoListButtons =
    !isEmpty(message.todoItems) &&
    [
      MessagesPermissions.READ_MESSAGE_CHECK_ALL_BUTTON,
      MessagesPermissions.READ_MESSAGE_UNCHECK_ALL_BUTTON,
      MessagesPermissions.READ_MESSAGE_EDIT_TODO_LIST_BUTTON
    ].some((a) => currentUser.hasPermissions(a));

  const withAddsButtons = [
    isEmpty(message.todoItems)
      ? MessagesPermissions.READ_MESSAGE_CREATE_TODO_LIST_BUTTON
      : null,
    MessagesPermissions.READ_MESSAGE_ADD_TO_DESCRIPTION_BUTTON,
    MessagesPermissions.READ_MESSAGE_ADD_TO_RECORDS_BUTTON,
    message.classification === MessageClassifications.UNHAPPY ||
    message.classification === MessageClassifications.MOST_LIKELY_UNHAPPY
      ? MessagesPermissions.READ_MESSAGE_TOGGLE_AI_NOT_RELEVANT_BUTTON
      : null
  ].some((a) => a && currentUser.hasPermissions(a));

  const withOtherButtons = [
    MessagesPermissions.READ_MESSAGE_COPY_ID_BUTTON,
    MessagesPermissions.READ_MESSAGE_COPY_UUID_BUTTON,
    quantityTypesAttachments > 1
      ? MessagesPermissions.READ_MESSAGE_DOWNLOAD_ATTACHMENTS_BUTTON
      : null,
    size(message.fileAttachments) > 0
      ? MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENTS_BUTTON
      : null,
    size(message.selectedProducts) > 0
      ? MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCTS_BUTTON
      : null,
    size(message.selectedLifestyles) > 0
      ? MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLES_BUTTON
      : null,
    size(message.selectedMaterials) > 0
      ? MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_MATERIALS_BUTTON
      : null,
    withPropagateButton
      ? MessagesPermissions.READ_MESSAGE_PROPAGATE_BUTTON
      : null,
    MessagesPermissions.READ_MESSAGE_RED_BUTTON,
    message.whiteboardKeyId && message.threadKeyId
      ? MessagesPermissions.READ_MESSAGE_OPEN_IN_WHITEBOARD_LINK
      : null,
    withSubmitAsTaskResultsButton
      ? TasksPermissions.READ_TASK_SUBMIT_RESULTS_BUTTON
      : null,
    withSubmitSourceFilesButton
      ? MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON
      : null,
    size(images) > 0
      ? MessagesPermissions.READ_MESSAGE_SEND_IMAGES_TO_WHITEBOARD_BUTTON
      : null,
    size(message.fileAttachments) > 0
      ? MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENTS_UPLOAD_TO_LIBRARY_BUTTON
      : null
  ].some((a) => a && currentUser.hasPermissions(a));

  return (
    <Fragment>
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_REPLY_BUTTON}>
        {!message.body && size(message.fileAttachments) > 0 && (
          <div className="sm:block hidden">
            <MessageDropdownReplyModalButton
              message={message}
              onReplyMessage={onReplyMessage}
            />
          </div>
        )}

        <div className="sm:hidden block">
          <MessageDropdownReplyModalButton
            message={message}
            onReplyMessage={onReplyMessage}
          />
        </div>
      </CheckPermissions>

      {message.body &&
        message.messageType ===
          MessageMessageTypes.ANSWER_CHATGPT_DISCUSSION_MESSAGE && (
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGE_PUBLISH_BUTTON}
          >
            <div className="sm:hidden block">
              <PublishMessageButton
                i18nText={words.publish}
                isDropdown
                message={message}
              />
            </div>
          </CheckPermissions>
        )}

      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_FORWARD_BUTTON}
      >
        {forwardMessageProjectUuid && (
          <Fragment>
            <MessageDropdownForwardModalButton
              message={message}
              messagesCacheKey={messagesCacheKey}
              projectUuid={forwardMessageProjectUuid}
              taskUuid={forwardMessageTaskUuid}
            />
            <DropdownDivider />
          </Fragment>
        )}
      </CheckPermissions>

      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_CREATE_TODO_LIST_BUTTON}
      >
        <MessageDropdownCreateTodoListButton message={message} />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_ADD_TO_DESCRIPTION_BUTTON}
      >
        <MessageDropdownAddToDescriptionButton message={message} />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_ADD_TO_RECORDS_BUTTON}
      >
        <MessageDropdownAddToRecordsButton message={message} />
      </CheckPermissions>
      {(message.classification === MessageClassifications.UNHAPPY ||
        message.classification ===
          MessageClassifications.MOST_LIKELY_UNHAPPY) && (
        <CheckPermissions
          action={
            MessagesPermissions.READ_MESSAGE_TOGGLE_AI_NOT_RELEVANT_BUTTON
          }
        >
          <MessageDropdownToggleAiNotRelevantButton
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </CheckPermissions>
      )}
      {withAddsButtons && <DropdownDivider />}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_EDIT_TODO_LIST_BUTTON}
      >
        <MessageDropdownUpdateTodoListButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_CHECK_ALL_BUTTON}
      >
        <MessageDropdownCheckTodoItemsButton message={message} />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_UNCHECK_ALL_BUTTON}
      >
        <MessageDropdownUncheckTodoItemsButton message={message} />
      </CheckPermissions>
      {withTodoListButtons && <DropdownDivider />}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_COPY_ID_BUTTON}
      >
        <MessageDropdownCopyIdButton message={message} />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_COPY_UUID_BUTTON}
      >
        <MessageDropdownCopyUuidButton message={message} />
      </CheckPermissions>
      {quantityTypesAttachments > 1 ? (
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_DOWNLOAD_ATTACHMENTS_BUTTON}
        >
          <MessageDropdownDownloadAttachments message={message} />
        </CheckPermissions>
      ) : (
        <Fragment>
          {size(message.fileAttachments) > 0 && (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENTS_BUTTON
              }
            >
              <MessageDropdownDownloadFileAttachments message={message} />
            </CheckPermissions>
          )}
          {size(message.selectedProducts) > 0 && (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCTS_BUTTON
              }
            >
              <MessageDropdownDownloadSelectedProducts message={message} />
            </CheckPermissions>
          )}
          {size(message.selectedLifestyles) > 0 && (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLES_BUTTON
              }
            >
              <MessageDropdownDownloadSelectedLifestyles message={message} />
            </CheckPermissions>
          )}

          {size(message.selectedMaterials) > 0 && (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_MATERIALS_BUTTON
              }
            >
              <MessageDropdownDownloadSelectedMaterials message={message} />
            </CheckPermissions>
          )}
        </Fragment>
      )}
      {withPropagateButton && (
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_PROPAGATE_BUTTON}
        >
          <MessageDropdownPropagateModalButton
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </CheckPermissions>
      )}
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_RED_BUTTON}>
        <MessageDropdownRedButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
      {message.whiteboardKeyId && message.threadKeyId && (
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_OPEN_IN_WHITEBOARD_LINK}
        >
          <MessageOpenInWhiteboardLink
            i18nText={whiteboardsKeys.open}
            message={message}
            className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
          />
        </CheckPermissions>
      )}
      {withSubmitAsTaskResultsButton && (
        <CheckPermissions
          action={TasksPermissions.READ_TASK_SUBMIT_RESULTS_BUTTON}
        >
          <MessageDropdownSendResult message={message} />
        </CheckPermissions>
      )}
      {withSubmitSourceFilesButton && (
        <SubmitSourceFilesToTaskModalButton
          taskNanoId={message.task.nanoId}
          className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
          i18nText={tasksKeys.submitAsSourceFile}
          initialFiles={message.fileAttachments}
        />
      )}
      {size(images) > 0 && (
        <CheckPermissions
          action={
            MessagesPermissions.READ_MESSAGE_SEND_IMAGES_TO_WHITEBOARD_BUTTON
          }
        >
          <SendImagesToWhiteboardPageDynamicModalButton
            cacheKeys={[messagesCacheKey]}
            images={images}
            isProjectWhiteboard={!!projectNanoId}
            isResults={message.isResult}
            projectNanoId={projectNanoId || message.task?.project?.nanoId}
            taskNanoId={message.task?.nanoId}
            className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
            i18nText={stringsKeys.sendToWhiteboard}
          />
        </CheckPermissions>
      )}
      {withUploadToLibraryButton && (
        <MessageDropdownUploadToLibraryButton message={message} />
      )}
      {withOtherButtons && <DropdownDivider />}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_FLAGGED_BUTTON}
      >
        <MessageDropdownFlaggedButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_FAVORITE_BUTTON}
      >
        <MessageDropdownFavoriteButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>

      <div className={withPinButton ? 'flex' : 'sm:hidden flex'}>
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_PINNED_BUTTON}
        >
          <MessageDropdownPinnedButton
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </CheckPermissions>
      </div>
      <div className="sm:hidden flex">
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON}
        >
          <MessageDropdownVFCButton
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </CheckPermissions>
      </div>
      {onSetCheckedMessage && (
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_SELECT_BUTTON}
        >
          <DropdownDivider />

          <MessageDropdownSelectButton
            message={message}
            checkedMessages={checkedMessages}
            onSetCheckedMessage={onSetCheckedMessage}
          />
        </CheckPermissions>
      )}
    </Fragment>
  );
}

export default MessageDropdownContent;
